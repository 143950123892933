<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="i18nEntityTypesList.items"
		:options.sync="options"
		:server-items-length="i18nEntityTypesList.total"
		class="elevation-1"
		:footer-props="{
			itemsPerPageOptions: [5,  15, 25, 50, 100],
			itemsPerPageText: 'Строк на стр.'
		}"
		loading-text="Загрузка данных"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Типы справочников для перевода</v-toolbar-title>
				<v-divider vertical inset class="mx-6"/>
				<v-dialog
					v-model="dialogEdit"
					max-width="500px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="primary"
							dark
							class="white--text"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left dark>mdi-plus</v-icon>
							Добавить
						</v-btn>
					</template>
					<v-card>
						<v-card-title>{{ formTitle }}</v-card-title>
						<v-card-text>
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
							>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.name"
												label="Имя"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.description"
												label="Описание"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.source"
												label="Source"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								color="grey darken-1"
								text
								@click="resetEditedItem"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="saveItem"
								:disabled="!valid"
							>
								{{ editedIndex === -1 ? 'Создать' : 'Сохранить' }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title>Точно удалить?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="grey darken-1" text @click="resetEditedItem">Отмена</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-spacer></v-spacer>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn
				icon
				@click="editItem(item)"
				small
			>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
			<v-btn
				icon
				small
				@click="deleteItem(item)"
			>
				<v-icon small>mdi-delete</v-icon>
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="resetList"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'Типы справочников для перевода',
			options: {},
			dialogEdit: false,
			dialogDelete: false,
			loading: true,
			headers: [
				{
					text: 'ID',
					align: 'start',
					sortable: false,
					value: 'id'
				},
				{
					text: 'Имя',
					sortable: false,
					value: 'name'
				},
				{
					text: 'Описание',
					sortable: false,
					value: 'description'
				},
				{
					text: 'Source',
					sortable: false,
					value: 'source'
				},
				{
					text: 'Действия',
					value: 'actions',
					sortable: false
				}
			],
			editedIndex: -1,
			editedItem: {
				id: '',
				description: '',
				name: '',
				source: ''
			},

			defaultItem: {
				id: '',
				description: '',
				name: '',
				source: ''
			},

			rules: {
				required: value => !!value || 'Поле обязательно'
			},

			valid: true,

			filters: {}
		}
	},

	computed: {
		...mapGetters({
			i18nEntityTypesList: 'i18n/i18nEntityTypesList'
		}),

		formTitle () {
			return this.editedIndex === -1 ? 'Новый тип справочника' : 'Редактировать тип справочника'
		},

		/* Фильтры */
		urlFilters () {
			/* Инициализация фильтров */
			const urlFilters = this.$route.query
			const filters = {}

			filters.page = +urlFilters.page || 1
			filters.limit = +urlFilters.limit || 15

			return filters
		}
	},

	watch: {
		/* Отслеживание изменения фильтров */
		'$route' (to, from) {
			this.initialize()
		},

		/* Отслеживание изменения состояния таблицы */
		options (to) {
			const filters = {}
			if (this.urlFilters.page !== to.page) {
				filters.page = to.page
			}

			if (this.urlFilters.limit !== to.itemsPerPage) {
				filters.limit = to.itemsPerPage
			}

			if (filters.page || filters.limit) {
				this.updateUrl(filters)
			}
		},

		dialogEdit (val) {
			if (!val) {
				this.resetEditedItem()
			}
		},

		dialogDelete (val) {
			if (!val) {
				this.resetEditedItem()
			}
		}
	},

	created () {
		/* Инициализация фильтров */
		this.$set(this, 'filters', this.urlFilters)
		this.initialize()
	},

	methods: {
		...mapActions({
			getTranslateEntityTypesList: 'i18n/getTranslateEntityTypesList',
			deleteTranslateEntityTypesList: 'i18n/deleteTranslateEntityTypesList',
			putTranslateEntityTypesList: 'i18n/putTranslateEntityTypesList',
			postTranslateEntityTypesList: 'i18n/postTranslateEntityTypesList'
		}),

		async initialize () {
			this.loading = true

			this.$set(this, 'options', {
				...this.options,
				itemsPerPage: this.urlFilters.limit,
				page: this.urlFilters.page
			})

			try {
				await this.getDataFromApi()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},

		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.push({
				query: {
					...this.$route.query,
					...query
				}
			})
		},

		updateFilters (filter, value) {
			const filters = {
				[filter]: value ? String(value) : undefined,
				page: undefined
			}

			this.updateUrl(filters)
		},

		async getDataFromApi () {
			this.loading = true

			await this.getTranslateEntityTypesList({
				limit: this.urlFilters.limit,
				offset: (this.urlFilters.page - 1) * this.urlFilters.limit
			}
			).then(
				() => {},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)

			this.loading = false
		},

		/* Очистка данных диалоговых окон */
		resetEditedItem () {
			this.$nextTick(() => {
				this.$set(this, 'editedIndex', -1)
				this.$set(this, 'editedItem', this.defaultItem)
				this.$set(this, 'dialogDelete', false)
				this.$set(this, 'dialogEdit', false)
			})
		},

		deleteItemConfirm () {
			this.deleteTranslateEntityTypesList(this.editedItem).then(
				() => {
					this.$notify({ type: 'info', title: 'Тип справочника удален' })
					this.resetEditedItem()
				},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)
		},

		deleteItem (item) {
			this.$set(this, 'editedIndex', this.i18nEntityTypesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogDelete', true)
		},

		editItem (item) {
			this.$set(this, 'editedIndex', this.i18nEntityTypesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogEdit', true)
		},

		saveItem () {
			try {
				this[this.editedIndex > -1 ? 'putTranslateEntityTypesList' : 'postTranslateEntityTypesList'](
					this.editedItem
				).then(
					() => {
						this.$notify({ type: 'info', title: this.editedIndex > -1 ? 'Изменения сохранены' : 'Тип справочника добавлен' })
						this.getDataFromApi()
						this.resetEditedItem()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		async resetList () {
			await this.updateUrl({
				page: undefined,
				limit: undefined
			})

			await this.initialize()
		}
	}
}
</script>
